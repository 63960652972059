import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: "Avenir";
    src: url("../../../static/fonts/avenir/AvenirLTStd-Book.otf");
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    /* background: #173746; */
  }
  
  body {
    -webkit-font-smoothing: antialiased;
  }
  
  a, button {
    font: 12px Montserrat, Roboto;
    text-decoration: none;
    text-transform: uppercase;
    font-style: normal;
  }

  button {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
      display: none;
  }
`;